@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,700;0,800;0,900;1,800&display=swap');

*{
  font-family:  'Poppins', sans-serif;;
}
#root{
  background-image: url('./assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
svg{
  margin: 0 5px;
}
.App {
  text-align: center;
  width: 95%;
  max-width: 100%;
    margin: 100px auto;
}

#app-show-case{
  display: flex;
  align-items: center;

  overflow: hidden;
}
.item{
  overflow: hidden;
  height: 260px;
}

#app-show-case .overlay {
  width: 880px;
  z-index: 2;
  position: absolute;
  height: 680px;
  transform: scale(0.4, 0.4);
  transform-origin: top left;
}
.tool  {
  width: 880px;
  height: 650px;
  transform: scale(0.4, 0.4);
  transform-origin: top left;
}



#love {
  color: #fff;
  width: 200px;
  bottom: 24px;
  margin: 100px auto;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  text-transform: capitalize;
  filter: drop-shadow(2px 4px 6px black);
}
  #love  a{
      text-decoration: none;
  }
  #love  span#logo {
      display: block;
      background: linear-gradient(96.02deg, #0CDAC4 0.22%, #395DF0 31.45%, #FD49AD 71.02%, #FF845A 104.34%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      --WebkitTextFillColor: transparent;
  }
  #love  img{
      width: 40px;
  }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
